import { create } from 'zustand';
import { createLog } from '../../../auth/authLog';
import axios from 'axios';
import { Member } from '../../../types';
import { breathubAPIContainer } from '../../context';

interface Me {
  fetch: () => Promise<Member>;
  data?: Member;
  isLoading: boolean;
}

const log = createLog('👤 useMe');

export const useMember = create<Me>((setState) => {
  return {
    data: undefined,
    isLoading: false,
    fetch: async () => {
      setState({ isLoading: true });
      log("Fetching user's data");
      return breathubAPIContainer.userClient
        .getMember()
        .then((response) => {
          setState({ data: response });
          return response;
        })
        .finally(() => setState({ isLoading: false }));
    },
  };
});
