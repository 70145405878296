import { AxiosInstance, AxiosPromise } from 'axios';
import { SignInRequest, SignUpRequest, UserTokens } from '../auth/types/types.ts';
import { ServiceResponse } from '../types/ServiceResponse.ts';
import { Member } from '../types';

export class AuthClient implements AuthAPIClient {
  constructor(
    readonly loginClient: AxiosInstance,
    readonly verifyLoginClient: AxiosInstance,
    readonly refreshLoginClient: AxiosInstance
  ) {}

  getNewToken = () => this.refreshLoginClient.get('/refreshtoken');

  verifySession = () => this.verifyLoginClient.get('/v1/member');

  signUpWithEmailAndPassword = (signUpRequest: SignUpRequest) =>
    this.loginClient.post('/v1/signUp', signUpRequest);
  loginWithEmailAndPassword = (loginRequest: SignInRequest) =>
    this.loginClient.post<ServiceResponse<Member>>('/v1/signIn', loginRequest);
  logout = () => this.verifyLoginClient.post('/v1/signOut');
  getOnboardingInfo = () =>
    this.verifyLoginClient
      .get('/v1/infrastructure/getMemberGuestOnboardingInfo')
      .then((response) => response.data.results);
  loginLog = (obj: any) =>
    this.loginClient.post('/v1/LoginLog', obj).then((response) => response.data.results);
}

export interface AuthAPIClient {
  readonly loginClient: AxiosInstance;
  readonly verifyLoginClient: AxiosInstance;
  // registerAnonymousUser: () => AxiosPromise<Response<UserTokens>>;
  getNewToken: (refreshToken: string) => AxiosPromise<ServiceResponse<UserTokens>>;
  verifySession: () => AxiosPromise;
  loginWithEmailAndPassword: (
    signInRequest: SignInRequest
  ) => AxiosPromise<ServiceResponse<Member>>;
  signUpWithEmailAndPassword: (signUpRequest: SignUpRequest) => AxiosPromise;
  logout: () => AxiosPromise;
  getOnboardingInfo: () => AxiosPromise<ServiceResponse<boolean>>;
  loginLog: (obj: any) => AxiosPromise<ServiceResponse<boolean>>;
}
export interface AppleVerificationProps {
  user: string;
  email: string;
  fullName: string;
  nonce: string;
  identityToken: string;
  authorizationCode: string;
}
