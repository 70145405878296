import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiCamera } from 'react-icons/fi';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import SuccessMessage from '../../components/Forms/SuccessMessage';
import Sidebar from '../../components/Common/Sidebar';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';
import Functions from '../../tools/Functions';

import i18n from '../../i18n/i18n';

// const useQuery = () => new URLSearchParams(useLocation().search);
const ProfileScreen = () => {
  // const query = useQuery();
  const navigate = useNavigate();

  const { language } = useLanguage();
  const { member, registerMember, isMemberSignedin } = useMember();

  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const runAsync = async () => {
      const serverMember = await isMemberSignedin(language.appLanguage);
      if (serverMember) {
        if (serverMember.picture) {
          setSelectedImage(serverMember.picture);
        }

        setLoading(false);
      } else {
        navigate('/members/signin');
      }
    };

    runAsync();

    // const rdmQuery = query.get('rdm');
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();

    setLoading(true);

    const file = e.target.files[0];

    const bodyData = new FormData();

    bodyData.append('file', file, file.name);

    const parameters = {
      method: 'POST',
      url: 'v1/uploadpicture',
      apiType: 'memberApi',
      data: bodyData,
      headers: [{ AccessToken: member.token }, { 'Content-Type': 'multipart/form-data' }],
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      const memberData = member;

      memberData.picture = request.data.results;

      setSelectedImage(memberData.picture);

      registerMember(memberData);
    } else {
      setSuccess({ title: 'Error', message: request.title, buttonText: 'OK' });
    }

    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  const isHavePermanentPayment = () => {
    if (
      member?.communityMemberSubscriptions &&
      Array.isArray(member?.communityMemberSubscriptions)
    ) {
      const find = member?.communityMemberSubscriptions.find(
        (item) => item.latestSubscriptionType === 'one time' && item.status
      );

      return find;
    }

    return false;
  };

  return (
    <>
      <SeoHeader robots={false} title={i18n.t('accountHome')} />
      <SiteLayout headerType='white absolute' footerType='green' hideFooter>
        <section className='gray flex sidebar-container'>
          <Sidebar />
          <div className='right-content flex flex-column flex-center'>
            <div className='member-boxes transition'>
              <div className='box med-box'>
                <div className='member-info-container flex flex-vertical-center no-select'>
                  <form className='form' noValidate>
                    <div className='member-image'>
                      <input type='file' id='file' onChange={handleChange} accept='.jpg, .jpeg' />
                      <label htmlFor='file'>
                        {selectedImage ? (
                          <img src={selectedImage} alt='' draggable='false' />
                        ) : (
                          <img
                            src='https://breathhubimagecdn.azureedge.net/web/other/member-picture.png'
                            alt=''
                            draggable='false'
                          />
                        )}
                        <div className='change-image'>
                          <FiCamera />
                        </div>
                      </label>
                    </div>
                  </form>
                  <div className='member-info'>
                    <h3>
                      {member.name} {member.lastname}
                    </h3>
                    <p>{member.emailAddress}</p>
                  </div>
                </div>
              </div>

              <div className='box med-box light-orange subscription no-select'>
                {member.subscription ? (
                  <>
                    <div className='flex flex-space-between'>
                      <h6>{i18n.t('subscription')}</h6>
                      <span>
                        {member.subscription.status ? i18n.t('active') : i18n.t('passive')}
                      </span>
                    </div>
                    <div>
                      <p>
                        {isHavePermanentPayment()
                          ? i18n.t('subscriptionPerm')
                          : member.subscription.name}
                        <br />
                        {i18n.t('through')}: {Functions.humanReadableDate(member.subscription.ends)}
                      </p>
                      {!member?.communityMemberSubscriptions?.length && (
                        <div>
                          <Link target='_blank' to={member?.subscription?.manageUrl}>
                            {i18n.t('manageSubscription')}
                          </Link>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex flex-space-between'>
                      <h6>{i18n.t('subscription')}</h6>
                      <span>{i18n.t('passive')}</span>
                    </div>
                    <div>
                      <p>{i18n.t('noSubscription')}</p>
                      <div>
                        <Link to={`/${language.appLanguage}`}>{i18n.t('tryForFree')}</Link>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {member?.communityMemberSubscriptions &&
                Array.isArray(member?.communityMemberSubscriptions) &&
                member.communityMemberSubscriptions.map((subscription) => (
                  <div
                    key={subscription.id}
                    className='box med-box light-green subscription no-select'
                  >
                    <div className='flex flex-space-between'>
                      <h5>{i18n.t('subscriptionCommunity')}</h5>
                      <span>{subscription.status ? i18n.t('active') : i18n.t('passive')}</span>
                    </div>
                    <div>
                      <p>
                        {subscription.latestSubscriptionType === 'yearly'
                          ? i18n.t('subscriptionCommunityOneYear')
                          : subscription.latestSubscriptionType === 'monthly'
                          ? i18n.t('subscriptionCommunityOneMonth')
                          : i18n.t('subscriptionCommunityOneTime')}
                        <br />
                        <i>{subscription.name}</i>
                        <br />
                        {i18n.t('through')}: {Functions.humanReadableDate(subscription.endDate)}
                      </p>
                      <div className='link'>
                        <Link target='_blank' to={subscription?.url}>
                          {i18n.t('communityPageLink')}
                        </Link>
                      </div>
                      <div>
                        <Link to='/members/subscriptions'>{i18n.t('manageSubscription')}</Link>
                      </div>
                    </div>
                  </div>
                ))}

              <div className='box med-box'>
                <p>
                  Breath Hub uygulamasını indirmek için QR taratabilirsin veya uygulama
                  marketlerinden indirebilirsin. Uygulamada Giriş Yap seçeneğini seçerek mevcut
                  email adresin ve şifrenle kullanmaya başlayabilirsin.
                </p>
              </div>

              <div className='row'>
                <div className='col-3 left redeem-bottom'>
                  <div className='stores'>
                    <div className='col-6'>
                      <img
                        src='https://breathhubimagecdn.azureedge.net/web/stores/qr-code.png'
                        alt='Qr Code'
                        className='qr-code-img'
                        draggable='false'
                      />
                    </div>
                  </div>
                </div>

                <div className='col-3 right redeem-bottom'>
                  <div className='stores'>
                    <div className='col-6'>
                      <a
                        href='https://apps.apple.com/app/id1531494892'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src='https://breathhubimagecdn.azureedge.net/web/stores/store-apple.png'
                          alt='App Store'
                          className='imgApp'
                          draggable='false'
                        />
                      </a>
                      <br />
                      <a
                        href='https://play.google.com/store/apps/details?id=app.breathhub.main1'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src='https://breathhubimagecdn.azureedge.net/web/stores/store-google.png'
                          alt='Google Play'
                          className='imgApp'
                          draggable='false'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {success && (
          <SuccessMessage
            title={success.title}
            message={success.message}
            buttonText={success.buttonText}
            click={() => setSuccess(null)}
          />
        )}
      </SiteLayout>
    </>
  );
};

export default ProfileScreen;
