import { createLog } from './authLog';
import { UserTokens } from './types/types';

export const authLog = createLog('🗝️ Auth', true);

const LOCAL_STORAGE_KEY = 'userInfo'; // Key for storing user info in localStorage
export interface KeychainData {
  id: string;
  accessToken: string;
  refreshToken: string;
}

export const getUserInfoFromLocalStorage = () => {
  const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (storedData) {
    try {
      const jsonResult = JSON.parse(storedData);
      authLog(`Found tokens ${jsonResult} `);
      return {
        id: jsonResult.id,
        ...jsonResult,
      };
    } catch (error) {
      authLog(`Error parsing localStorage data:, ${error}`);
      return undefined;
    }
  }
  return undefined;
};

export const setUserInfoInLocalStorage = (data?: UserTokens) => {
  if (!data?.refreshToken) {
    authLog('Clearing localStorage');
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  } else {
    authLog(`Setting localStorage, ${data}`);
    const { id, accessToken, refreshToken } = data;
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        id,
        accessToken,
        refreshToken,
      })
    );
  }
};
