import axios, { AxiosRequestHeaders } from 'axios';
import i18n from '../../i18n/i18n';
import { AppAuthManager } from '../../auth/AppAuthManager.ts';

export const externalClientGenerator = ({
  authManager,
  apiBaseUrl,
  getAccessToken,
  isAuthHeaderActive,
  isRefresh,
  getRefreshToken,
}: {
  authManager: AppAuthManager;

  apiBaseUrl: string;
  getAccessToken?: () => string | undefined;
  getRefreshToken?: () => string | undefined;
  isAuthHeaderActive?: boolean;
  isRefresh?: boolean;
}) => {
  const externalClient = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      ClientSecret:
        'ece0c31cc9474290be7756974a7bdeb8c51c2fbb1e6e4a138f50d884180ffeabc14a5f0e3b2f4b4798390f27d306ee2a',
      ClientId: '85245619',
      Language: i18n.locale || 'en',
    },
    timeout: 35000,
  });

  if (isRefresh) {
    externalClient.interceptors.request.use(async (value) => {
      const token = authManager.getStoredRefreshToken();
      value.headers = {
        ...value.headers,
        Authorization: `Bearer ${token}`,
      } as unknown as AxiosRequestHeaders;
      return value;
    });
  }
  if (isAuthHeaderActive) {
    externalClient.interceptors.request.use(async (value) => {
      const token = authManager.getStoredAccessToken();
      value.headers = {
        ...value.headers,
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders;
      return value;
    });
  }

  return externalClient;
};
