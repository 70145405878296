import axios, { AxiosResponse } from 'axios';
import { contentClient, purchaseClient, userApiClient } from '../api/api-clients/clients.ts';

const getClient = (apiType: 'memberApi' | 'contentApi' | 'purchaseApi') => {
  if (apiType === 'memberApi') {
    return userApiClient;
  }
  if (apiType === 'contentApi') {
    return contentClient;
  }
  if (apiType === 'purchaseApi') {
    return purchaseClient;
  }
  return axios;
};
/**
 * Get json value from server.
 *
 * @param {object} parameters - Request parameters
 */
const getResponse = async (parameters: any) => {
  let errorData = {};
  let successData = {};
  // If parameters is null
  if (parameters === null || parameters === undefined || !parameters.method) {
    errorData = {
      status: 0,
      title: 'Parameters or method not found.',
    };

    return errorData;
  }

  // Build url
  const client = getClient(parameters.apiType);

  const buildedUrl = `/${parameters.url}`;

  let response: AxiosResponse = {} as AxiosResponse;
  // Made request
  try {
    if (parameters.method === 'GET') {
      response = await client.get(buildedUrl, { timeout: 15000 });
    } else {
      response = await client.post(buildedUrl, parameters.data, { timeout: 15000 });
    }

    // Success request
    if (response.status === 200) {
      successData = {
        status: response.status,
        data: response.data,
      };

      return successData;
    }
    // Request failed
    errorData = {
      status: response.status,
      title: response.data.title || response.statusText,
    };

    return errorData;
  } catch (error) {
    // The request was made and the server responded with a status code
    if (error.response) {
      if (error.response.status === 404) {
        errorData = {
          status: error.response.status,
          title: error.response.data.title || error.response.data || 'Resource not found!',
        };

        return errorData;
      }
      errorData = {
        status: error.response.status,
        title: error.response.data.title || error.response.data,
      };

      return errorData;
    }
    if (error.request) {
      // The request was made but no response was received
      errorData = {
        status: 0,
        title: `No response received. Maybe DNS not resolved or server not found. ${parameters.url})`,
      };

      return errorData;
    }
    // Something happened in setting up the request that triggered an Error
    errorData = {
      status: 0,
      title: error.message,
    };

    return errorData;
  }
};

const Request = {
  getResponse,
};

export default Request;
