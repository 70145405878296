import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import ErrorMessage from '../../components/Forms/ErrorMessage';
import FormInput from '../../components/Forms/FormInput';
import FormButton from '../../components/Forms/FormButton';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPasswordScreen = () => {
  const query = useQuery();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    key: '',
    code: '',
    changed: '',
    confirm: '',
  });
  useEffect(() => {
    const key = query.get('key');
    const code = query.get('code');

    if (!key || key === '') {
      setResult({
        message: i18n.t('invalidParameters'),
      });

      setLoading(false);
    } else if (!code || code === '') {
      setResult({
        message: i18n.t('invalidParameters'),
      });

      setLoading(false);
    } else {
      const runAsync = async () => {
        setLoading(true);
        const parameters = {
          method: 'POST',
          url: 'v1/passwordreset/validation',
          apiType: 'memberApi',
          data: {
            memberId: key,
            activationCode: code,
          },
          language: language.appLanguage,
        };

        const request = await Request.getResponse(parameters);

        if (request.status === 200) {
          setStep(1);

          setFormValues({
            ...formValues,
            key,
            code,
          });
        } else {
          setResult({ message: request.title });
        }

        setLoading(false);
      };

      runAsync();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    setLoading(true);

    const parameters = {
      method: 'POST',
      url: 'v1/passwordreset/change',
      apiType: 'memberApi',
      data: {
        memberId: formValues.key,
        activationCode: formValues.code,
        changed: formValues.changed,
        confirm: formValues.confirm,
      },
      language: language.appLanguage,
    };
    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      setStep(2);

      setResult({ message: request.data.title });
    } else {
      setError({ message: request.title });
    }

    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader robots={false} title={i18n.t('passwordReset')} />
      <SiteLayout headerType='white' footerType='green'>
        {error && <ErrorMessage message={error.message} />}
        <section className='gray member-padding member-back'>
          <div className='container'>
            <div className='box min-box'>
              <div className='box-tabs'>
                <ul>
                  <li>
                    <div className='active'>{i18n.t('passwordReset')}</div>
                  </li>
                </ul>
              </div>
              {step === 0 && (
                <div className='transition'>
                  {result && <p>{result.message}</p>}
                  <div>
                    <Link to={`/${i18n.locale}`} className='button button-green button-large'>
                      {i18n.t('goToHomePage')}
                    </Link>
                  </div>
                </div>
              )}

              {step === 1 && (
                <div className='transition'>
                  <p>{i18n.t('resetPasswordDescription')}</p>
                  <form className='form' onSubmit={handleSubmit} noValidate>
                    <div className='form-elements'>
                      <div className='form-line'>
                        <label htmlFor='changed'>{i18n.t('newPasswordLabel')}</label>
                        <FormInput
                          type='password'
                          name='changed'
                          value={formValues.changed}
                          placeholder={i18n.t('newPasswordPlaceholder')}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='form-line'>
                        <label htmlFor='confirm'>{i18n.t('newPasswordConfirmLabel')}</label>
                        <FormInput
                          type='password'
                          name='confirm'
                          value={formValues.confirm}
                          placeholder={i18n.t('newPasswordConfirmPlaceholder')}
                          onChange={handleChange}
                        />
                      </div>
                      <div className='form-line'>
                        <div className='buttons'>
                          <FormButton
                            type='submit'
                            text={i18n.t('changePassword')}
                            onClick={handleSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {step === 2 && (
                <div className='transition'>
                  {result && <p>{result.message}</p>}
                  <div>
                    <Link to='/members/signin' className='button button-green button-large'>
                      {i18n.t('goToSignin')}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default ResetPasswordScreen;
