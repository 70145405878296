import { internalClientGenerator } from './internalClientGenerator.ts';
import { authManager } from '../../auth/composites/authManager.ts';
import { externalClientGenerator } from './externalClientGenerator.ts';
import { createLog } from '../../auth/authLog';
import { Config } from '../../config/Config';

const log = createLog('🌐 Client Interceptor');

export const userApiClient = internalClientGenerator({
  authManager,
  apiBaseUrl: Config.memberApi,
  log,
});

export const contentClient = internalClientGenerator({
  authManager,
  apiBaseUrl: Config.contentApi,
  log,
});

export const purchaseClient = internalClientGenerator({
  authManager,
  apiBaseUrl: Config.purchaseApi,
  log,
});

export const loginClient = externalClientGenerator({
  authManager,
  apiBaseUrl: Config.memberApi,
});

export const verifyLoginClient = externalClientGenerator({
  authManager,
  apiBaseUrl: Config.memberApi,
  isAuthHeaderActive: true,
});
export const refreshLoginClient = externalClientGenerator({
  authManager,
  apiBaseUrl: Config.memberApi,
  isRefresh: true,
});
