import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useLanguage from '../../hooks/useLanguage';

import Premium from '../../components/Sections/Premium';
import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import Loading from '../../components/Loading/Loading';
import Request from '../../tools/Request';
import i18n from '../../i18n/i18n';
import MainCoursePlayer from '../../components/Sections/MainCoursePlayer';
import MainSessionPlayer from '../../components/Sections/MainSessionPlayer';

const useQuery = () => new URLSearchParams(useLocation().search);

const HomeScreen = () => {
  const query = useQuery();

  const { language } = useLanguage();

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [prices, setPrices] = useState([]);
  const [tryContent, setTryContent] = useState('session');
  useEffect(() => {
    setLoading(false);
  }, [language]);

  useEffect(() => {
    const dl = query.get('dl');
    if (dl) {
      window.location.replace(dl);
    }
  }, []);

  useEffect(() => {
    const runAsync = async () => {
      setLoading(true);

      const parametersForContent = {
        method: 'GET',
        url: `v1/webapp/content`,
        apiType: 'memberApi',
        language: language.appLanguage,
      };

      const requestForContent = await Request.getResponse(parametersForContent);

      if (requestForContent.status === 200) {
        setContent(requestForContent.data.results);
      }

      const parametersForPrice = {
        method: 'GET',
        url: `v1/webapp/price-detail`,
        apiType: 'memberApi',
        language: language.appLanguage,
      };

      const requestForPrice = await Request.getResponse(parametersForPrice);

      if (requestForPrice.status === 200) {
        setPrices(requestForPrice.data.results);
      }

      setLoading(false);
    };

    runAsync();
  }, []);

  if (loading || !content || !prices) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader
        robots
        title='Breath Hub Web App'
        description='Breath Hub. Take a deep breath, your life is about to change.'
        keywords='breath hub, breathhub, breath hub app, your breath journey, take a deep breath, download breath hub, breath hub ios, breath hub android'
        canonical='https://webapp.breathhub.app'
      />
      <SiteLayout headerType='white' footerType='green'>
        {!loading && content && prices && (
          <Premium
            data={{
              content,
              prices,
            }}
          />
        )}

        <section className='padding-top center'>
          <button
            type='button'
            className={
              tryContent === 'session'
                ? 'button button-green button-medium'
                : 'button button-white button-medium'
            }
            onClick={() => setTryContent('session')}
          >
            {i18n.t('trySessionTab')}
          </button>
          &nbsp;
          <button
            type='button'
            className={
              tryContent === 'course'
                ? 'button button-green button-medium'
                : 'button button-white button-medium'
            }
            onClick={() => setTryContent('course')}
          >
            {i18n.t('tryCourseTab')}
          </button>
        </section>

        {tryContent === 'session' ? <MainSessionPlayer /> : <MainCoursePlayer />}
      </SiteLayout>
    </>
  );
};

export default HomeScreen;
