import { AppAuthManager } from '../AppAuthManager.ts';
import { SignInRequest, SignUpRequest } from '../types/types.ts';
import { publicAPIContainer } from '../../api/context';
import { useAuthStore } from '../../hooks/useAuthStore.ts';

export const authManager = new AppAuthManager(useAuthStore.getState, {
  verifySession: async () => {
    return publicAPIContainer.auth.verifySession().then((response) => response.data);
  },

  reloadToken: async (refreshToken: string) => {
    return publicAPIContainer.auth.getNewToken(refreshToken).then((response) => response.data);
  },
  signUpWithEmailAndPassword: async (signUpRequest: SignUpRequest) => {
    return publicAPIContainer.auth
      .signUpWithEmailAndPassword(signUpRequest)
      .then((response) => response.data);
  },
  loginWithEmailAndPassword: async (signInRequest: SignInRequest) => {
    return publicAPIContainer.auth
      .loginWithEmailAndPassword(signInRequest)
      .then((response) => response.data);
  },

  logout: async () => {
    return publicAPIContainer.auth.logout().then((response) => response.data);
  },
});
