import { appContainer, breathubAPIContainer, publicAPIContainer } from '../api/context';
import {
  loginClient,
  refreshLoginClient,
  userApiClient,
  verifyLoginClient,
} from '../api/api-clients/clients.ts';
import { authManager } from './composites/authManager.ts';
import { Config } from '../config/Config';
import { AuthClient } from '../api/AuthClient.ts';
import { UserClient } from '../api/UserClient.ts';

export const setupDIContainer = () => {
  appContainer.appConfig = Config;
  // appContainer.pushNotificationManager = pushNotificationManager;

  publicAPIContainer.auth = new AuthClient(loginClient, verifyLoginClient, refreshLoginClient);
  breathubAPIContainer.userClient = new UserClient(userApiClient);

  appContainer.authManager = authManager;
};
