import { Routes, Route } from 'react-router-dom';

import HomeScreen from '../screens/Home/HomeScreen';
import SitemapScreen from '../screens/Home/SitemapScreen';
import NotFoundScreen from '../screens/NotFound/NotFoundScreen';
import ContentScreen from '../screens/Content/ContentScreen';

import HelpScreen from '../screens/Help/HelpScreen';
import AnswerScreen from '../screens/Help/AnswerScreen';
import CategoryScreen from '../screens/Help/CategoryScreen';

import AboutScreen from '../screens/Content/AboutScreen';
import ContactScreen from '../screens/Content/ContactScreen';

import SigninScreen from '../screens/Members/SigninScreen';
import SignupScreen from '../screens/Members/SignupScreen';
import ProfileScreen from '../screens/Members/ProfileScreen';
import SignoutScreen from '../screens/Members/SignoutScreen';
import EditProfileScreen from '../screens/Members/EditProfileScreen';
import ChangeEmailScreen from '../screens/Members/ChangeEmailScreen';
import SubscriptionScreen from '../screens/Members/SubscriptionsScreen';
import NotificationsScreen from '../screens/Members/NotificationsScreen';
import ChangePasswordScreen from '../screens/Members/ChangePasswordScreen';
import ForgotPasswordScreen from '../screens/Members/ForgotPasswordScreen';
import ResetPasswordScreen from '../screens/Members/ResetPasswordScreen';
import ActivateEmailScreen from '../screens/Members/ActivateEmailScreen';
import ManageSubscription from '../screens/Members/ManageSubscription';
import ActivateMembershipScreen from '../screens/Members/ActivateMembershipScreen';

import OrderError from '../screens/Order/OrderError';
import OrderSuccess from '../screens/Order/OrderSuccess';

const Navigation = () => {
  return (
    <Routes>
      <Route path='/' element={<HomeScreen />} />
      <Route path='/site-map' element={<SitemapScreen />} />
      <Route path='/:lang' element={<HomeScreen />} />
      <Route path='/:lang/help' element={<HelpScreen />} />
      <Route path='/:lang/yardim' element={<HelpScreen />} />
      <Route path='/:lang/help/answer/:url' element={<AnswerScreen />} />
      <Route path='/:lang/yardim/cevap/:url' element={<AnswerScreen />} />
      <Route path='/:lang/help/yardim/:url' element={<AnswerScreen />} />
      <Route path='/:lang/help/category/:url' element={<CategoryScreen />} />
      <Route path='/:lang/yardim/kategori/:url' element={<CategoryScreen />} />
      <Route path='/:lang/about' element={<AboutScreen />} />
      <Route path='/:lang/legal/:url' element={<ContentScreen />} />
      <Route path='/:lang/hakkimizda' element={<AboutScreen />} />
      <Route path='/:lang/contact-us' element={<ContactScreen />} />
      <Route path='/order/error' element={<OrderError />} />
      <Route path='/order/success' element={<OrderSuccess />} />
      <Route path='/members' element={<ProfileScreen />} />
      <Route path='/members/signin' element={<SigninScreen />} />
      <Route path='/:lang/members/signup/:subId/' element={<SignupScreen />} />
      <Route path='/:lang/members/signup' element={<SignupScreen />} />
      <Route path='/members/forgot-password' element={<ForgotPasswordScreen />} />
      <Route path='/members/signout' element={<SignoutScreen />} />
      <Route path='/members/edit-profile' element={<EditProfileScreen />} />
      <Route path='/members/change-email' element={<ChangeEmailScreen />} />
      <Route path='/members/subscriptions' element={<SubscriptionScreen />} />
      <Route path='/members/notifications' element={<NotificationsScreen />} />
      <Route path='/members/change-password' element={<ChangePasswordScreen />} />
      <Route path='/members/password-reset' element={<ResetPasswordScreen />} />
      <Route path='/members/email-activation' element={<ActivateEmailScreen />} />
      <Route path='/members/activation' element={<ActivateMembershipScreen />} />
      <Route path='/manage/subscription/stripe' element={<ManageSubscription />} />
      <Route path='*' element={<NotFoundScreen />} status={404} />
    </Routes>
  );
};

export default Navigation;
