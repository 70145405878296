import { AxiosInstance } from 'axios';

export class UserClient {
  constructor(private userClient: AxiosInstance) {}

  getMember = () => this.userClient.get('/v1/member').then((response) => response.data.results);

  editProfile = () =>
    this.userClient.post('/v1/editprofile').then((response) => response.data.results);
  deleteMember = () =>
    this.userClient.post('/v1/deleteaccount').then((response) => response.data.results);
  getPurposeQuestions = () =>
    this.userClient
      .get('/v1/infrastructure/getPurposeQuestions')
      .then((response) => response.data.results);
  postPurposeQuestions = (variables: {
    AppId: string;
    AnswerId: string;
    MemberId: string;
    AppUniqueCode: string;
  }) =>
    this.userClient
      .post('/v1/infrastructure/postPurposeQuestions', variables)
      .then((response) => response.data.results);
  uploadPicture = async (bodyData: any) => {
    this.userClient
      .post(
        '/v1/infrastructure/getMemberGuestOnboardingInfo',
        { bodyData },
        {
          transformRequest: (formData: any) => formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => response.data.results);
  };

  getFavourites = (type?: number) =>
    this.userClient
      .post('/v1/getfavourites', { FavoriteType: type })
      .then((response) => response.data.results);
  getOnboardingShortContent = () =>
    this.userClient
      .get('/v1/infrastructure/getOnboardingShortContent')
      .then((response) => response.data.results);
  getDynamicSubscriptions = ({
    AppUniqueCode,
    MemberId,
    Language,
  }: {
    AppUniqueCode: string;
    MemberId: number;
    Language: string;
  }) =>
    this.userClient
      .post('/v1/dynamicpaywall/content', {
        AppUniqueCode,
        MemberId: MemberId ? MemberId : 0,
        Language,
      })
      .then((response) => response.data.results);
  addFavourite = (FavouriteType: number, Container: number, ContainerContent?: number) =>
    this.userClient
      .post('/v1/addtofavourite', {
        FavouriteType,
        Container,
        ContainerContent,
      })
      .then((response) => response.data.results);
  removeFavourite = (FavouriteType: number, Container: number, ContainerContent?: number) =>
    this.userClient
      .post('/v1/deletefromfavourite', {
        FavouriteType,
        Container,
        ContainerContent,
      })
      .then((response) => response.data.results);

  editNotifications = (newsletters: boolean) =>
    this.userClient
      .post('/v1/notificationchange', { newsletters })
      .then((response) => response.data.results);
  changePassword = (variables: { current: string; changed: string; confirm: string }) =>
    this.userClient.post('/v1/changepassword', variables).then((response) => response.data.results);
  changeEmail = (variables: { emailAddress: string; emailAddressAgain: string }) =>
    this.userClient
      .post('/v1/notificationchange', variables)
      .then((response) => response.data.results);
  resetPassword = (variables: { emailAddress: string }) =>
    this.userClient.post('/v1/passwordreset', variables).then((response) => response.data.results);
  loginLog = (obj: any) =>
    this.userClient.post('/v1/LoginLog', obj).then((response) => response.data.results);
}
