import { AuthManager } from '../../auth/AuthManager.ts';
import { Config } from '../../config/Config.ts';
import { AuthAPIClient } from '../AuthClient.ts';
import { UserClient } from '../UserClient.ts';

export const breathubClients: {
  internalClient: any;
  externalClient: any;
} = {
  externalClient: undefined!,
  internalClient: undefined!,
};
export const breathubAPIContainer: {
  userClient: UserClient;
} = {
  userClient: undefined!,
};
export const publicAPIContainer: {
  auth: AuthAPIClient;
} = {
  auth: undefined!,
};
export const appContainer: {
  authManager: AuthManager;
  // pushNotificationManager: PushNotificationManager;
  appConfig: typeof Config;
} = {
  authManager: undefined!,
  // pushNotificationManager: undefined!,
  appConfig: undefined!,
};
