import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import ErrorMessage from '../../components/Forms/ErrorMessage';
import FormInput from '../../components/Forms/FormInput';
import FormButton from '../../components/Forms/FormButton';
import RadioButton from '../../components/Forms/RadioButton';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';
import { authManager } from '../../auth/composites/authManager';

const useQuery = () => new URLSearchParams(useLocation().search);

const SigninScreen = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const { language } = useLanguage();

  const [tab, setTab] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState(null);
  const [formValues, setFormValues] = useState({
    emailAddress: '',
    password: '',
    name: '',
    lastname: '',
    passwordAgain: '',
    country: 0,
    newsletters: false,
    agreeToPolicies: false,
    agreeToPolicies2: false,
    key: '',
    code: '',
  });

  useEffect(() => {
    const redirectedUrl = query.get('redirect');

    if (redirectedUrl && redirectedUrl.match(/^[a-z0-9-/]*$/)) {
      setDestination(redirectedUrl);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleSigninSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    setLoading(true);
    authManager
      .loginWithEmailAndPassword({
        emailAddress: formValues.emailAddress,
        password: formValues.password,
        remember: true,
      })
      .then((request) => {
        if (request.code === 200) {
          if (destination) {
            navigate(destination);
          } else {
            navigate('/members');
          }
          return;
        } else {
          setLoading(false);

          setError({ message: request.title });
        }
      })
      .catch((e) => setError({ message: e?.response.data.title }))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader
        robots
        title={i18n.t('signin')}
        description='Sign in to your account.'
        keyword='sign in, sign in to your account, breath hub, breath hub app'
        canonical='https://webapp.breathhub.app/members/signin'
      />
      <SiteLayout headerType='white' footerType='green'>
        {error && <ErrorMessage message={error.message} />}
        <section
          className={
            tab === 0 ? 'gray member-padding member-back' : 'gray member-padding member-back2'
          }
        >
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <div className='box min-box'>
                  <div className='box-tabs'>
                    <ul>
                      <li>
                        <div
                          aria-hidden='true'
                          className={tab === 0 ? 'active' : 'passive'}
                          onClick={() => {
                            setTab(0);
                            setError(null);
                          }}
                        >
                          {i18n.t('signin')}
                        </div>
                      </li>
                      {/* <li className='pad-left'>
                        <div
                          aria-hidden='true'
                          className={tab === 1 || tab === 2 ? 'active' : 'passive'}
                          onClick={() => {
                            setTab(1);
                            setError(null);
                          }}
                        >
                          {i18n.t('signup')}
                        </div>
                      </li> */}
                    </ul>
                  </div>

                  {tab === 0 && (
                    <div className='transition'>
                      <p>{i18n.t('signinDescription')}</p>
                      <form className='form' onSubmit={handleSigninSubmit} noValidate>
                        <div className='form-elements'>
                          <div className='form-line'>
                            <label htmlFor='emailAddress'>{i18n.t('emailLabel')}</label>
                            <FormInput
                              type='email'
                              name='emailAddress'
                              value={formValues.emailAddress}
                              placeholder={i18n.t('emailPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='password'>{i18n.t('passwordLabel')}</label>
                            <FormInput
                              type='password'
                              name='password'
                              value={formValues.password}
                              placeholder={i18n.t('passwordPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>

                          <div className='form-line'>
                            <div className='buttons'>
                              <FormButton
                                type='submit'
                                text={i18n.t('signin')}
                                onClick={handleSigninSubmit}
                              />
                            </div>
                          </div>
                          <div className='form-line center'>
                            <Link to='/members/forgot-password'>
                              {i18n.t('forgotPasswordLink')}
                            </Link>
                          </div>
                          <div className='form-line center'>
                            <Link to={`/${language.appLanguage}/members/signup`}>
                              {i18n.t('signup')}
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {tab === 1 && (
                    <div className='transition'>
                      <p>{i18n.t('signupDescription')}</p>
                      <form className='form' onSubmit={handleSignupSubmit} noValidate>
                        <div className='form-elements'>
                          <div className='form-line'>
                            <label htmlFor='name'>{i18n.t('nameLabel')}</label>
                            <FormInput
                              type='text'
                              name='name'
                              value={formValues.name}
                              placeholder={i18n.t('namePlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='lastname'>{i18n.t('lastnameLabel')}</label>
                            <FormInput
                              type='text'
                              name='lastname'
                              value={formValues.lastname}
                              placeholder={i18n.t('lastnamePlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='emailAddress'>{i18n.t('emailLabel')}</label>
                            <FormInput
                              type='email'
                              name='emailAddress'
                              value={formValues.emailAddress}
                              placeholder={i18n.t('emailPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='password'>{i18n.t('passwordLabel')}</label>
                            <FormInput
                              type='password'
                              name='password'
                              value={formValues.password}
                              placeholder={i18n.t('passwordPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <label htmlFor='passwordAgain'>{i18n.t('passwordConfirmLabel')}</label>
                            <FormInput
                              type='password'
                              name='passwordAgain'
                              value={formValues.passwordAgain}
                              placeholder={i18n.t('passwordConfirmPlaceholder')}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='form-line'>
                            <RadioButton
                              name='newsletters'
                              checked={formValues.newsletters}
                              text={i18n.t('marketingPermission')}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className='form-line'>
                            <RadioButton
                              name='agreeToPolicies'
                              checked={formValues.agreeToPolicies}
                              text={i18n.t('permissions1')}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className='form-line'>
                            <RadioButton
                              name='agreeToPolicies2'
                              checked={formValues.agreeToPolicies2}
                              text={i18n.t('permissions2')}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className='form-line'>
                            <div className='buttons'>
                              <FormButton
                                type='submit'
                                text={i18n.t('signup')}
                                onClick={handleSignupSubmit}
                              />
                            </div>
                          </div>
                          <div className='form-line'>
                            <div className='links'>
                              <Link to={`/${language.appLanguage}/legal/terms-and-conditions`}>
                                {i18n.t('termsAndConditions')}
                              </Link>
                              &bull;
                              <Link to={`/${language.appLanguage}/legal/privacy-policy`}>
                                {i18n.t('privacyPolicy')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  {tab === 2 && (
                    <div className='transition'>
                      <p>{i18n.t('activationDescription')}</p>
                      <form className='form' onSubmit={handleActivationSubmit} noValidate>
                        <div className='form-elements'>
                          <div className='form-line'>
                            {/* <label htmlFor='code'>{i18n.t('activationCodeLabel')}</label> */}
                            {/* <FormInput
                              type='text'
                              name='code'
                              value={formValues.code}
                              placeholder={i18n.t('activationCodePlaceholder')}
                              onChange={handleChange}
                            /> */}
                          </div>
                          <div className='form-line'>
                            {/* <div className='buttons'>
                              <FormButton
                                type='submit'
                                text={i18n.t('activateMyAccount')}
                                onClick={handleActivationSubmit}
                              />
                            </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default SigninScreen;
