import { create } from 'zustand';
import { getUserInfoFromLocalStorage, setUserInfoInLocalStorage } from '../auth/userInfoKeyChain';
import { UserTokens } from '../auth/types/types.ts';
import { AuthStatus } from '../auth/AuthManager.ts';
export interface AuthStore {
  data?: UserTokens;
  status: AuthStatus;
  setUserData: (data: UserTokens) => Promise<void>;
  setStatusAndUserData: <T extends AuthStatus>(
    status: T,
    data?: UserTokens
  ) => Promise<{
    status: T;
    data?: UserTokens;
  }>;
  setStatus: <T extends AuthStatus>(status: T) => T;
  reset: () => Promise<void>;
  logout: () => Promise<Extract<AuthStatus, 'UNAUTHORIZED'>>;
}
const createAuthStore = () =>
  create<AuthStore>((setState) => ({
    data: undefined,
    status: 'IDLE',
    setUserData: async (data) => {
      await setUserInfoInLocalStorage(data);
      console.log(data);
      setState({ data });
    },
    setStatus: (status) => {
      setState({ status });
      return status;
    },
    setStatusAndUserData: async (status, data) => {
      await setUserInfoInLocalStorage(data);
      setState({ status, data });
      return {
        status,
        data,
      };
    },
    // getRefreshToken: async () => {
    //   if (!this?.data?.refreshToken) {
    //     const data = getUserInfoFromLocalStorage();
    //     setState({ data });
    //     return data.refreshToken;
    //   }
    //   return this.data.refreshToken;
    // },
    // getAccessToken: async () => {
    //   if (!this?.data?.accessToken) {
    //     const data = getUserInfoFromLocalStorage();
    //     if (data) {
    //       setState({ data });
    //       return data.accessToken;
    //     }
    //     return undefined;
    //   }
    //   return this.data.accessToken;
    // },
    reset: async () => {
      await setUserInfoInLocalStorage(undefined);
      setState({ data: undefined, status: 'IDLE' });
    },

    logout: async () => {
      await setUserInfoInLocalStorage(undefined);
      setState({ status: 'UNAUTHORIZED', data: undefined });
      return 'UNAUTHORIZED';
    },
  }));

export const useAuthStore = createAuthStore();
