import { Link, useNavigate } from 'react-router-dom';

import SiteLayout from '../../components/Layouts/SiteLayout';
import SeoHeader from '../../components/SeoHeader/SeoHeader';
import useLanguage from '../../hooks/useLanguage';
import i18n from '../../i18n/i18n';

const OrderError = () => {
  const { language } = useLanguage();
  i18n.locale = language.appLanguage;
  const navigate = useNavigate();
  return (
    <>
      <SeoHeader
        robots={false}
        title={i18n.t('orderErrorTitle')}
        description={i18n.t('orderErrorDescription')}
        canonical='https://webapp.breathhub.app/order/error'
      />
      <SiteLayout headerType='white' footerType='green'>
        <section className='white padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <div className='section-title center'>
                  <h4>{i18n.t('orderErrorTitle')}</h4>

                  <p>{i18n.t('orderErrorDescription')}</p>
                </div>

                <p className='center'>
                  <Link to={`/${i18n.locale}`} className='button button-green button-medium'>
                    {i18n.t('goToHome')}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default OrderError;
