import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import useAnalytics from './hooks/useAnalytics';
import Navigation from './navigation/Navigation';

import MemberProvider from './contexts/MemberContext';
import DrawerProvider from './contexts/DrawerContext';
import LanguageProvider from './contexts/LanguageContext';
import LocationProvider from './contexts/LocationContext';
import { setupDIContainer } from './auth/setupDIContainer';
const App = () => {
  setupDIContainer();

  useAnalytics();

  useEffect(() => {
    TagManager.initialize({ gtmId: 'G-G6F27JLHGW' });
  }, []);

  return (
    <MemberProvider>
      <DrawerProvider>
        <LanguageProvider>
          <LocationProvider>
            <Navigation />
          </LocationProvider>
        </LanguageProvider>
      </DrawerProvider>
    </MemberProvider>
  );
};

export default App;
