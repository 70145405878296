import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Database from '../tools/Database';
import { useMember } from '../api/query/member/useMember';
import { useAuthStore } from '../hooks/useAuthStore';
import { authManager } from '../auth/composites/authManager';
import { useNavigate } from 'react-router-dom';
import i18n from '../i18n/i18n';

const tableName = 'member';

const initialState = {
  member: null,
  removeMember: () => {},
  registerMember: () => {},
  isMemberSignedin: async () => {},
};

export const MemberContext = createContext(initialState);

const MemberProvider = ({ children }) => {
  const [member, setMember] = useState(Database.getData(tableName) || initialState.member);
  const navigate = useNavigate();

  const { data, fetch } = useMember();

  // Access the setUserData method from the auth store
  const setUserData = useAuthStore((state) => state.setUserData);
  useEffect(() => {
    if (!data) {
      authManager.checkInitialSession().then((res) => {
        if (res === 'AUTHORIZED') {
          fetch();
          navigate('/members');
        } else if (res === 'UNAUTHORIZED') {
          authManager.logout().then(() => {
            navigate(`/${i18n.locale}`);
          });
        }
      });
    }
  }, [data]);
  /**
   * Add or update signed-in member in the database.
   *
   * @param {object} details - Member data
   */
  const registerMember = (details) => {
    Database.setData(tableName, details);

    // Call setUserData from the auth store
    setUserData({
      accessToken: details.accessToken,
      refreshToken: details.refreshToken,
    }).then(() => setDetails(details));

    setMember(details);
  };

  /**
   * Remove signed-in member in the database.
   */
  const removeMember = () => {
    Database.removeData(tableName);
    remove();
    setMember(null);
  };

  /**
   * Get signed-in member state. If DB contains no member data, returns null.
   */
  const isMemberSignedin = async () => {
    if (!data) {
      return authManager.checkInitialSession().then((res) => {
        if (res === 'AUTHORIZED') {
          return fetch().then((res) => {
            return res;
          });
        } else {
          return undefined;
        }
      });
    }
    return data;
  };

  /**
   * Create context values.
   */
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    member: data,
    removeMember: () => removeMember(),
    registerMember: (details) => registerMember(details),
    isMemberSignedin: async (language) => isMemberSignedin(language),
  };

  return <MemberContext.Provider value={contextValue}>{children}</MemberContext.Provider>;
};

MemberProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MemberProvider;
