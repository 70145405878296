import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import { AppAuthManager } from '../../auth/AppAuthManager.ts';
import i18n from '../../i18n/i18n';

export const internalClientGenerator = ({
  authManager,
  apiBaseUrl,
  log,
}: {
  authManager: AppAuthManager;
  apiBaseUrl: string;
  log: (message: string) => void;
}) => {
  const client = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      ClientSecret:
        'ece0c31cc9474290be7756974a7bdeb8c51c2fbb1e6e4a138f50d884180ffeabc14a5f0e3b2f4b4798390f27d306ee2a',
      ClientId: '85245619',
    },
  });

  const requestLock = {
    isLocked: false,
  };

  const checkLockAndBlock = () => {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        log('Checking the lock.');
        if (!requestLock.isLocked) {
          clearInterval(intervalId);
          resolve(true);
        }
      }, 15000);
    });
  };

  client.interceptors.request.use(async (value) => {
    if (requestLock.isLocked) {
      await checkLockAndBlock();
      log('Unlocked....');
    }
    const token = authManager.getStoredAccessToken();

    value.headers = {
      ...value.headers,
      Language: i18n.locale || 'en',
    } as unknown as AxiosRequestHeaders;

    if (token) {
      value.headers = {
        ...value.headers,
        Authorization: `Bearer ${token}`,
      } as unknown as AxiosRequestHeaders;
    }
    return value;
  });
  client.interceptors.response.use(undefined, async (error: AxiosError<{ error?: string }>) => {
    const errorCode = error.code;
    const statusCode = error.status;
    const errorResponse = error.response?.data.error;
    const errorUrl = error?.config?.url;
    log(
      'Client error - ' +
        errorCode +
        ' ' +
        statusCode +
        ' ' +
        errorResponse +
        ' ' +
        ` (${errorUrl})`
    );

    if (statusCode === 401) {
      try {
        if (requestLock.isLocked) {
          await checkLockAndBlock();
          log('Unlocked....');
        }
        log('Has error: ' + statusCode);
        const refreshToken = authManager.getStoredRefreshToken();
        if (refreshToken) {
          log('Refresh token is existed: ' + refreshToken);

          requestLock.isLocked = true;
          const result = await authManager.reloadTokens(refreshToken);
          requestLock.isLocked = false;

          log('Result: ' + result);

          /**
           * If it is unauthorized, show the sign-in page.
           */
          // if (result === 'UNAUTHORIZED') {
          //   log('Redirecting the sign-in page');
          //   navigateToSignIn();
          //   // resetAndNavigate('SignIn');
          //   return;
          // }

          log('Retrying the original request.');
          const originalRequest = error.config;
          if (!originalRequest) {
            console.log('Something went wrong. Please try again.');
            return;
          }
          return client(originalRequest);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return Promise.reject(error);
  });

  return client;
};
