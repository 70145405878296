import { memo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const SeoHeader = memo(({ title, image, description, keywords, canonical, robots }) => {
  const navigate = useNavigate();
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='robots' content={robots ? 'index, follow' : 'noindex, nofollow'} />
      {description && <meta name='description' content={description} />}
      {keywords && <meta name='keywords' content={keywords} />}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      {description && <meta property='og:description' content={description} />}
      {/* {canonical && <meta property='og:url' content={canonical} />} */}
      {canonical && <link rel='canonical' href={canonical} />}
      <meta
        onClick={() => navigate('/')}
        property='og:image'
        content={image || 'https://breathhubimagecdn.azureedge.net/web/logos/192x192.png'}
      />
      {canonical && <link rel='canonical' href={canonical} />}
    </Helmet>
  );
});

SeoHeader.defaultProps = {
  description: null,
  keywords: null,
  canonical: null,
  image: null,
};

SeoHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.string,
  canonical: PropTypes.string,
  robots: PropTypes.bool.isRequired,
  image: PropTypes.string,
};

export default SeoHeader;
